export const environment = {
  production: false,
  svAPIKey: "KHLBeta321",
  baseUrl: "https://staging.public.api.lafka.dev/api/v1",
  googleAppId:
    "734567008925-nvbbrlecot0d1dip8pb7f16anu34i2mp.apps.googleusercontent.com",
  facebookAppId: "1021525404867816",
  firebase: {
    apiKey: "AIzaSyDYWIzJR1VL3mfg-OigYG9QlP3jPXhMXBo",
    authDomain: "storeversekh24-beta-staging.firebaseapp.com",
    projectId: "storeversekh24-beta-staging",
    storageBucket: "storeversekh24-beta-staging.appspot.com",
    messagingSenderId: "818501075902",
    appId: "1:818501075902:web:28ea7e2af3c89cacb06fef",
    measurementId: "G-W5DQ70HC30",
  },
};
